$myColor: black;

#home_h1 {
    color: $myColor;
}

.center_image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }