body {
    background: #f6d7b0;
}

ul{
    top: 50%; left: 50%;
    margin-top: 10px; margin-left: -200px;
    padding: 0px;
    list-style: none;
    text-align: center;
}

li{
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    display: inline-block;
    padding: 0px 15px;
    color: #fff;
}

.icon {
    margin-top: 10px; margin-left: -1100px;
    padding: 0px 0px;
    text-align: left;
    text-decoration: none;
    color: black;
}

.link {
    position: relative;
    text-decoration: none;
    color: black;
}

.link:after{
    content: '';
    position: absolute;
    width: 0; height: 3px;
    display: block;
    margin-top: 5px;
    right: 0;
    color: #fff;
    transition: width .2s ease;
    -webkit-transition: width .2s ease;
}

.link:hover:after{
    width: 100%;
    left: 0;
    background: black;
}